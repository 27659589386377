.timesheets-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.timesheets-table-header {
  background-color: var(--grey-background-color);
  border: none;
}
.timesheets-table-item {
  position: relative;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px var(--grey-background-color) solid;
}
.timesheets-table-item > * {
  width: 15%;
}
.timesheets-table-item input {
  width: 0 !important;
}
.timesheets-table-item > *:nth-child(1) {
  width: 40%;
  padding-right: 8px;
}
.timesheets-table-item > button {
  width: 15%;
  padding: 6px;
}
.timesheets-status {
  position: relative;
  padding-left: 16px;
}
.timesheets-status::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--red);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.timesheets-status[status="SUBMITTED"]::before {
  background-color: var(--secondary-blue);
}
.timesheets-status[status="SENT_TO_SIGN"]::before {
  background-color: var(--secondary-blue);
}
.timesheets-status[status="APPROVED"]::before {
  background-color: var(--primary-blue);
}
.timesheets-status[status="ADMIN_APPROVED"]::before {
  background-color: var(--primary-blue);
}
.timesheets-status[status="REJECTED"]::before {
  background-color: var(--red);
}
.timesheets-illustration {
  width: 100%;
  max-width: 240px;
}
.show-more {
  position: absolute;
  top: 40px;
  right: 12px;
  background-color: white;
  box-shadow: 0px 6px 20px -6px rgba(0, 0, 0, 0.77);
  z-index: 2;
  width: 200px;
}
.close-show-more {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: unset;
}
