.row-odd {
  background-color: rgb(249 249 249);
}

.rows > div {
  width: 100%;
  text-align: center;
  border-bottom: lightgrey 1px solid;
  padding: 1em 0;
  font-size: 0.85em;
}

.href:hover {
  color: var(--primary-blue);
  cursor: pointer;
}

.green-background-light {
  background-color: var(--primary-blue);
  color: white;
  font-weight: 500;
}

.timesheetComment { 
  color: red;
}
