.img-container {
  width: 20vw;
  max-width: 300px;
  max-height: 300px;
}

.img-container > img {
  max-width: 100%;
  max-height: 100%;
}
