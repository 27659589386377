.container {
  padding: 12px;
}

.container > h2 {
  font-family: "TT Trailers";
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: var(--black-ny);
  margin-bottom: 0.5em;
}
