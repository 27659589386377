.list-item {
  height: fit-content;
  position: relative;
  color: white !important;
  background-color: var(--primary-blue);
  padding: 12px 24px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  transition: 0.3s all ease-in;
  -webkit-transition: 0.3s all ease-in;
  -moz-transition: 0.3s all ease-in;
  -ms-transition: 0.3s all ease-in;
  -o-transition: 0.3s all ease-in;
}

.list-item > .delete {
  display: none;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.list-item:hover {
  padding-right: 40px;
}

.list-item:hover > .delete {
  display: inline;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  width: 18px;
  height: 18px;
  color: var(--primary-font-color);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.search-input {
  padding-left: 36px;
}


.skill {
  position: relative;
  padding: 8px 24px;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.hoverManager:hover {
  background-color: lightgray;
}
