.navbar {
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  background-color: white;
  z-index: var(--navbar-z-index);
  height: 90px;
  width: 100vw;
}
.navbar-right {
  width: 100px;
}
.navbar-right.open {
  width: 300px;
}
.nav-dropdown {
  position: absolute;
  flex-direction: column;
  top: calc(100% + 16px);
  right: 12px;
  min-width: 230px;
  gap: 24px 0;
  padding: 16px 0;
  background-color: white;
  box-shadow: 0 14px 22px -8px rgba(24, 39, 75, 0.12),
    0 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 2;
}
.profile {
  gap: 16px;
  padding-right: 2rem;
}

.close-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
