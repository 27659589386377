.icon-container{
    color: white;
    background-color: var(--primary-blue);
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow: hidden;
}
.icon-container.society{
    background-color: white;
}
.icon-container>*{
    font-size: 32px;
}
.icon-container>img{
    max-width: 100%;
    max-height: 100%;
}