.custom-radio{
    position: relative;
    height: 18px;
    width: 18px;
    border: 1px var(--grey) solid;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -o-transition: .5s all ease;
}
.custom-radio::after{
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -o-transition: .5s all ease;
}

input[type='radio']:checked~.custom-radio{
    border: 1px var(--primary-blue) solid;
}
input[type='radio']:checked~.custom-radio::after{
    background-color: var(--primary-blue);
}