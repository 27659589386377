@font-face {
    font-family: "TT Trailers"; /*Can be any text*/
    src: local("TT Trailers Variable.ttf"),
      url("../fonts/TT Trailers Variable.ttf");
  }
  
  .header-1 {
    font-family: "TT Trailers";
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    color: var(--primary-font-color);
  }
  
  .header-2 {
    font-family: "TT Trailers";
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: var(--black-ny);
  }
  
  .header-3 {
    font-family: "TT Trailers";
      font-weight: 600;
      font-size: 40px;
      line-height: 40px;
      color: var(--primary-gray);
  }
  
  .header-4 {
    font-family: "Helvetica";
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: var(--black-ny);
  }
  
  .wow-subtitle {
    font-family: "Helvetica";
    font-weight: 500;
    font-size: 28px;
    line-height: 27px;
    color: var(--primary-font-color);
  }
  
  .wow-body {
    font-size: 16px;
    line-height: 24px;
    color: var(--black-ny);
  }
  
  .wow-body > .fixedSvg {
    width: 20px;
    height: 20px;
  }
  
  .small-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary-font-color);
  }
  
  .button-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
  .bold {
    font-weight: 600;
  }
  .green-text {
    color: var(--primary-blue);
    font-weight: 500;
  }
  .blue-text {
    color: var(--secondary-blue);
    font-weight: 600;
  }
  .red-text {
    color: var(--red);
  }
  a.green-text:hover {
    color: var(--primary-blue);
  }
  
  .black-text {
    color: var(--black-ny);
  }
  
  a.black-text:hover {
    color: var(--black-ny);
  }
  
  .see-more {
    color: var(--secondary-blue);
    cursor: pointer;
  }
  
  .align-right {
    text-align: right;
  }
  