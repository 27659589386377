.search-bar{
    position: relative;
    padding: 0;
    min-height: 54px;
}
.search-bar input.split{
    padding-left: 40px;
}
.search-bar input{
    border-color: black;
    background-color: white;
    border-radius: 17px;
    padding-left: 40px;
}
.search-bar .search-icon{
    position: absolute;
    top: 50%;
    left: 8px;
    font-size: 24px;
    color: black;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}