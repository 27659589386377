.block-a {
    background-color: white;
    color: black;
    border: 2px var(--grey) solid;
    padding: 4px 12px 8px 12px;
    border-radius: 4px;
    border-color: var(--primary-blue);
    cursor: pointer;
}

.block-a:hover {
    border-color: black;
}

.grid-container {
    display: grid;
    grid-template-areas: 
    "a b"
    "c d";
    width: 100%;
    grid-template-rows: 20px;
    grid-template-columns: 290px 130px;
}

.title {
    color: var(--primary-blue);
    font-weight: 500;
}

.grid-container .a {
    grid-area: a;
}
.grid-container .b {
    grid-area: b;
}
.grid-container .c {
    grid-area: c;
}
.grid-container .d {
    grid-area: d;
}
.grid-container .e {
    grid-area: e;
}
.grid-container .f {
    grid-area: f;
}
