.show-more {
  position: absolute;
  top: 40px;
  right: 12px;
  background-color: white;
  box-shadow: 0px 6px 20px -6px rgba(0, 0, 0, 0.77);
  z-index: 2;
  width: 200px;
}
.close-show-more {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: unset;
}
