.container {
    display: flex;
    flex-direction: column;
}

.container ::-webkit-scrollbar {
    width: 6px; /* Largeur de la scrollbar */
    border-radius: 3px; /* Arrondi des coins de la scrollbar */
}

.container ::-webkit-scrollbar-thumb {
    background-color: var(--primary-light-gray); /* Couleur du "pouce" de la scrollbar */
    border-radius: 3px; /* Arrondi des coins du "pouce" */
}