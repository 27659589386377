table {
  width: 350px;
  border-collapse: collapse;
  margin-top: 1em;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  height: 50px;
}

.table th {
  background-color: var(--primary-blue);
  color: white;
}
