.container {
  width: auto;
  border-radius: 17px;
  background-color: white;
  border: 2px solid var(--primary-blue);
}

.container > p {
  font-size: 0.90em;
}

.container:hover {
  border: 2px solid black;
  cursor: pointer;
}
