.block-a {
    background-color: white;
    color: black;
    border: 2px var(--grey) solid;
    padding: 4px 12px 8px 12px;
    border-radius: 4px;
  }
  
.block-a title {
    display: block;
    font-weight: 600;
    font-size: large;
}

.block-a .rank {
    background-color: black;
    border-radius: 50%;
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 22px;
}

.grid-container {
    display: grid;
    grid-template-areas: 
    "a b c"
    "d e f";
    width: 100%;
    grid-template-rows: 20px 20px;
    grid-template-columns: 290px 130px 100px;
}

.title {
    color: var(--primary-blue);
    font-weight: 500;
}

.grid-container .a {
    grid-area: a;
}
.grid-container .b {
    grid-area: b;
}
.grid-container .c {
    grid-area: c;
}
.grid-container .d {
    grid-area: d;
}
.grid-container .e {
    grid-area: e;
}
.grid-container .f {
    grid-area: f;
}
