.default-btn{
    background-color: black;
    color: white;
    border: 2px black solid;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    padding: 16px 24px;
    border-radius: 17px;
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.default-btn:hover{
    background-color: white;
    color: black;
}

.default-btn > svg{
    font-size: 18px;
}

.default-btn.blue-btn{
    color: var(--secondary-blue);
    background-color: white;
    border: 2px var(--secondary-blue) solid;
}
.default-btn.blue-btn:hover{
    color: white;
	background-color: var(--secondary-blue);
}


.default-btn.danger-btn{
    color: var(--red);
    background-color: white;
    border: 2px var(--red) solid;
}
.default-btn.danger-btn:hover{
    color: white;
    background-color: var(--red);
}

.default-btn.cancel-btn {
    color: var(--primary-light-gray);
	background-color: white;
	border: 2px var(--primary-light-gray) solid;
}
.default-btn.cancel-btn:hover{
    color: white;
	background-color: var(--primary-light-gray);
}