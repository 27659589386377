@import "./Typographie.css";
@import "./text-inputs.css";
@import "./buttons.css";
@import "./checkbox.css";
@import "./cards.css";
@import "./radio.css";
@import "./toast.css";
@import "./fullcalendar.css";
@import "./files-loader.css";
@import "./circular-progressbar.css";
@import "./icon-container.css";
@import "./switch.css";
@import "./multi-select.css";

:root  {
  --primary-font-color: #0e0e2c; 
  --primary-green: #048c74;
  --primary-light-blue: #9bd1c7;
  --primary-light-gray: #9B9B9B;
  --primary-gray: #686868;

  
  --primary-blue: #8ab2c6;
  --primary-light-blue: #A6C3D1;

  
  --secondary-blue: #638a9e;

  
  
  --secondary-light-gray: #6c6c6c;
  --secondary-blue: #016452;
  --secondary-blue: #4f7e96;

  --peach-tech: #F4DDC5;
  --green-conseil: #A4BCB0;
  --blue-engineering: #A6C3D1;

  --red: #cc444b;
  --danger: #d1646a;
  --yellow: #f7ff03;
  --grey: #b1b1b1;
  --grey-2: #e2e2e2;
  --grey-3: rgba(35, 41, 49, 0.2);
  --background-color: #f6f8fa;
  --disabled-grey: #e0e0e0;
  --grey-background-color: #edf2f7;
  --popup-background-color: rgba(14, 14, 44, 0.25);

  --blue-ny: #a6c3d1;

  --navbar-z-index: 999;
  --navbar-mobile-menu-z-index: 1000;
  --notifications-z-index: 1001;
  --popup-z-index: 1002;
  --files-loader-z-index: 9999;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  overflow-x: hidden;
  color: var(--primary-font-color);
  background-color: var(--background-color);
  height: 100%;
}

#root {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

* {
  color: inherit;
  letter-spacing: 0px;
  scroll-behavior: auto;
  margin: 0;
  padding: 0;
  font-family: "Helvetica";
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--primary-font-color);
}

a:hover {
  color: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.wow-logo {
  height: 24px;
  width: fit-content;
}

.progress-bar-container {
  background-color: var(--grey-2);
  flex: 1;
  height: 16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.progress-bar-progress {
  background-color: var(--primary-blue);
  height: 16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.disponible {
  fill: var(--primary-blue);
  color: var(--primary-blue);
}

.indisponible {
  fill: var(--grey);
  color: var(--grey);
}

.lastname {
  text-transform: uppercase;
}

.firstname {
  text-transform: lowercase capitalize;
}

.liked {
  fill: var(--red) !important;
  color: var(--red);
}

@media (max-width: 1200px) {
  #root {
    display: none;
  }
}

.search-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  max-width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px var(--grey) solid;
  border-top: none;
  z-index: 2;
}

.search-content > ul {
  padding: 0;
  list-style-type: none;
}

.search-content > ul > li {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  list-style-type: none;
}

.search-content > ul > li:hover {
  background-color: var(--grey);
}


.font-family-tt {
  font-family: "TT Trailers";
}

.close-search-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

.search-content > div {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
}

.submit-btn-disabled {
  background-color: var(--disabled-grey);
  border: none;
  color: grey;
}

.border-primary-wow {
  border: 1px solid var(--primary-blue);
}

.border-secondary-wow {
  border: 1px solid var(--primary-light-blue);
}

.border-light-grey {
  border: 1px solid var(--grey);
  box-shadow: 0px 6px 20px -6px rgba(0, 0, 0, 0.77);
  border-radius: 12px;
}

.primary-color-text {
  color: var(--primary-blue);
}

.border-light-grey-small {
  border: 1px solid var(--grey);
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.77);
  border-radius: 12px;
}
