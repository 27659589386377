.btn {
  width: 250px;
  margin-left: auto;
}

.managers > ul > li {
  list-style: none;
  margin: 10px 0;
}

.managers > ul {
  margin: 0;
  padding: 0;
}

.managers {
  margin-top: 10px;
  padding: 0 10px;
  max-height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
}
