.row-odd {
  background-color: rgb(249 249 249);
}

.rows > div {
  width: 100%;
  text-align: center;
  border-bottom: lightgrey 1px solid;
  padding: 1em 0;
  font-size: 0.85em;
}

.filter-section {
  display: flex;
  align-items: center;
}

.filter-section > div:first-child,
.filter-section > div:last-child {
  width: 35%;
}

.filter-section > div:first-child{
  margin: 0 3em 0 0;
}

.filter-section > div:last-child {
  margin: 0 0 0 3em
}

.checkboxes{
  margin: 1em 0;
}

.href:hover {
  color: var(--primary-blue);
  cursor: pointer;
}
