.block-a {
    background-color: white;
    color: black;
    border: 2px var(--grey) solid;
    padding: 4px 12px 8px 12px;
    border-radius: 4px;
    border-color: var(--primary-blue);
    cursor: pointer;
}

.block-a:hover {
    border-color: black;
}
  
.block-a .title {
    font-weight: 600;
    font-size: large;
}
  
.block-a .secondary-title {
    font-weight: 500;
    white-space: nowrap; /* Évite le retour à la ligne */
    overflow: hidden; /* Masque le débordement */
    text-overflow: ellipsis; /* Ajoute les points de suspension */
}


.block-a .rank {
    background-color: black;
    border-radius: 50%;
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 22px;
}

.drop-down-menu {
    display: flex;
    flex-direction:column;
    gap:0.25rem;
    position: absolute;
    background-color: white;
    padding: 4px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.drop-down-menu button {
    text-align: start;
    width: 100%;
    border-radius: 5px;
    background: none;
    padding: 4px;
    border: 0;
}

.drop-down-menu button:hover {
    background: var(--primary-light-blue);
    padding: 4px;
    border: 0;
}

.grid-container {
    display: grid;
    grid-template-areas: 
    "a b c"
    "d e f";
    width: 100%;
    grid-template-rows: 24px 24px;
    grid-template-columns: 200px 140px minmax(60px, auto);

    overflow: hidden;
}

.title {
    color: var(--primary-blue);
    font-weight: 500;
}

.subtitle {
    font-weight: 500;
    font-size: 12px;
}

.grid-container .a {
    grid-area: a;
}
.grid-container .b {
    grid-area: b;
}
.grid-container .c {
    grid-area: c;
}
.grid-container .d {
    grid-area: d;
}
.grid-container .e {
    grid-area: e;
}
.grid-container .f {
    grid-area: f;
}

.grid-container .a,
.grid-container .b,
.grid-container .c,
.grid-container .d,
.grid-container .e,
.grid-container .f {
    white-space: nowrap; /* Évite le retour à la ligne */
    overflow: hidden; /* Masque le débordement */
    text-overflow: ellipsis; /* Ajoute les points de suspension */
}


.rotate-icon {
    transform: rotate(0deg); /* Position initiale à 0° */
    transition: transform 0.3s; /* Transition de 0.3 seconde */
}

.rotate-icon:hover {
    animation: rotate-animation 0.4s linear; /* Animation continue au survol */
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg); /* Rotation initiale à 0° */
    }
    100% {
        transform: rotate(360deg); /* Rotation à -20° à la fin */
    }
}