.sort-button-container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
  }
  
  .sort-button-container:hover {
    text-decoration: underline;
  }