.nav-item{
    padding: 24px 0;
    padding-bottom: 18px;
    border-bottom: 6px solid white;
    transition: .3s all ease-in-out;
    -webkit-transition: .3s all ease-in-out;
    -moz-transition: .3s all ease-in-out;
    -ms-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
}
.nav-item.active{
    border-bottom: 6px solid var(--primary-blue);
}