.default-input {
  width: 100%;
  font-size: 14px;
  padding: 13px;
  border: 2px black solid;
  color: black;
  background-color: white;
  border-radius: 17px;
  outline: none;
}

.default-input::placeholder {
  color: var(--primary-light-gray);
}

label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 6px;
  color: var(--primary-font-color);
}

.toggle {
  padding-bottom: 0.25rem;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.toggle label {
  margin-bottom: 0px;
  cursor: inherit;
  color: inherit;
}

.toggle:hover {
  color: var(--primary-blue);
}

.default-input:disabled {
  background-color: var(--grey-2);
}
.show-pwd {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  cursor: pointer;
}

.text-area {
  min-height: 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
