.skill {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    padding: 8px 24px;
    background-color: var(--primary-blue);
    color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.skill:hover{
    background-color: var(--secondary-blue);
}