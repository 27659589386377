.search-content {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px var(--grey) solid;
  border-top: none;
}

.search-content > div {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
}

.hoverManager:hover {
  background-color: lightgray;
}

.manager {
  position: relative;
  padding: 8px 24px;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
