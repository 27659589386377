.manager-card {
  cursor: pointer;
  width: 100%;
  margin: auto;
  padding: 12px;
  outline: 1px solid var(--grey);
  border-radius: 12px;
  margin-bottom: 30px;
}

.manager-card:hover {
  outline: 1px solid var(--primary-blue);
  transform: scale(1.01);
  box-shadow: 0px 0px;
}

.presentation {
  width: 30%;
}

.img-container {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.img-container > img {
  max-width: 100%;
  max-height: 100%;
}
.logo-container {
  color: white;
  background-color: var(--primary-blue);
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.logo-container > * {
  font-size: 32px;
}
.more {
  font-size: 24px;
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;
}
.open-more {
  position: absolute;
  top: 40px;
  right: 12px;
  background-color: white;
  box-shadow: 0px 6px 20px -6px rgba(0, 0, 0, 0.77);
  z-index: 2;
}

.close-open-more {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.manager-card > .footer > label {
  font-style: italic;
  text-decoration: underline;
  margin-right: 12px;
}
