.block-a {
  background-color: white;
  color: black;
  border: 2px var(--grey) solid;
  padding: 16px 24px 16px 24px;
  border-radius: 4px;
}

.block-a title {
  display: block;
  font-weight: 600;
  font-size: large;
  padding-bottom: 8px;
}

.block-a h1 {
  display: block;
  font-weight: 500;
  font-size: medium;
  padding: 0;
  margin: 0;
}

.block-a pre {
  white-space: pre-wrap;
}

button.toggle {
  padding-bottom: 0.25rem;
  align-items: center;
  background: none;
  border: none;
}

button.toggle:hover{
  color: var(--primary-blue);
}

.block-a .prompt{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Helvetica";
}