.table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.table-header {
  background-color: var(--grey-background-color);
  border: none;
}

.table-header > div {
  color: var(--grey);
  text-align: center;
}

.table-item {
  position: relative;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  text-align: center;
  font-size: 0.75em;
  align-items: center;
  border-bottom: 1px var(--grey-background-color) solid;
}

.table-item > div {
  font-size: inherit;
}

.table-item > *:last-child {
  width: 10%;
}
.table-item > * {
  width: 18%;
}

.show-more {
  position: absolute;
  top: 40px;
  right: 12px;
  background-color: white;
  box-shadow: 0px 6px 20px -6px rgba(0, 0, 0, 0.77);
  z-index: 2;
  width: 200px;
}
.close-show-more {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: unset;
}

.status {
  margin: auto;
  margin-top: 10px;
  content: "";
  left: 0;
  top: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--red);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.status[status="CREATED"] {
  background-color: var(--grey-background-color);
}
.status[status="ONGOING"] {
  background-color: var(--primary-blue);
}
.status[status="TO_COME"] {
  background-color: var(--secondary-blue);
}
.status[status="SOON_TERMINATED"] {
  background-color: var(--yellow);
}
.status[status="TERMINATED"] {
  background-color: var(--red);
}
