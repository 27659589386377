.accordion-form {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  -ms-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
}

.action{
  max-width: 300px;
  margin: 2em 0 0 auto;
}
