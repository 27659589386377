/* Styles pour le composant MultiSelect */
.multi-select {
  position: relative;
  width: 200px;
  font-family: Arial, sans-serif;
}

/* Styles pour l'en-tête du sélecteur */
.select-header {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styles pour l'en-tête du sélecteur lorsqu'il est ouvert */
.select-header.open {
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

/* Styles pour le texte de l'en-tête du sélecteur */
.select-header .placeholder {
  color: #999;
}

/* Styles pour les options */
.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

/* Styles pour une option */
.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Styles pour une option sélectionnée */
.option.selected {
  background-color: #f2f2f2;
}

/* Styles pour une option au survol */
.option:hover {
  background-color: #f7f7f7;
}
