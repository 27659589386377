.applications-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.applications-table-header {
  background-color: var(--grey-background-color);
  border: none;
}
.applications-table-item {
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px var(--grey-background-color) solid;
}
.applications-table-item > * {
  width: 15%;
}
.applications-table-item input {
  width: 0 !important;
}
.applications-table-item > *:nth-child(1) {
  width: 25%;
  padding-right: 8px;
}
.applications-table-item > button {
  width: 15%;
  padding: 6px;
}
.applications-status {
  position: relative;
  padding-left: 16px;
}
.applications-status::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--red);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.applications-status[status="PENDING"]::before {
  background-color: var(--secondary-blue);
}
.applications-status[status="APPROVED"]::before {
  background-color: var(--primary-blue);
}
.applications-status[status="REFUSED"]::before {
  background-color: var(--red);
}
