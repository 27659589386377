.CircularProgressbar-path {
    stroke: var(--primary-blue) !important;
}

.CircularProgressbar-trail {
    stroke: var(--grey-2) !important;
}

.CircularProgressbar-text {
    fill: var(--primary-font-color) !important;
    font-family: 'Helvetica' !important;
    font-size: 16px !important;
}
