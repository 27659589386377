:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
}
.Toastify__toast-container {
  width: 80vw;
  max-width: 700px;
}
.Toastify__close-button {
  height: 50px;
  color: white !important;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Toastify__close-button > svg {
  height: 32px;
  width: 32px;
}
.my-toast {
  color: white !important;
}
.my-toast.success {
  background-color: var(--primary-blue);
}
.my-toast.error {
  background-color: var(--red);
}
.my-toast.info {
  background-color: var(--secondary-blue);
}
.my-toast .icon {
  width: 32px;
  height: 32px;
}

@media (max-width: 768px) {
  .Toastify__toast-container {
    max-width: 350px;
    right: 0 !important;
    left: auto;
  }
}
