.table {
  width: 100%;
}

.table-content {
  max-height: 500px;
  overflow-y: scroll;
}

.header{
  display: flex;
}

.header > div {
  width: 100%;
  text-align: center;
  color: white;
  background-color: var(--primary-blue);
  height: 3em;
  line-height: 3em;
  font-weight: 500;
  border-bottom: 1px solid lightgrey;
}