/* Styles pour la timeline */
.timeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
}

.timeline-item:last-child {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: auto;
}

/* Styles pour les cases à cocher personnalisées */
.custom-checkbox {
  text-align: center;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-checkbox input {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative; /* Ajout de la position relative pour le pseudo-élément */
}

.custom-checkbox input:disabled {
  cursor: default;
}

/* Ajout du pseudo-élément pour le point */
.custom-checkbox input::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Ajustement pour le centrage */
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

/* Styles pour le point en état checked */
.custom-checkbox input:checked::before {
  background-color: black;
}

/* Styles au survol pour le point lorsque non coché */
.custom-checkbox input:not(:checked):hover::before {
  background-color: gray; /* Le point devient gris en hover non coché */
}

/* Ajout de styles pour la ligne connectrice */
.line-connector {
  margin-top: 33px;
  margin-left: -50px;
  margin-right: -50px;
  flex: 1; /* Pour occuper tout l'espace horizontal disponible */
  height: 2px; /* Hauteur de la ligne connectrice */
  z-index: 0; /* Placez-le en arrière-plan */
}
