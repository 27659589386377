.mission-img-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.mission-img-container > img {
  max-width: 100%;
  max-height: 100%;
}
.search-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  width: 18px;
  height: 18px;
  color: var(--primary-font-color);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.search-input {
  padding-left: 36px;
}

.search-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px var(--grey) solid;
  border-top: none;
  z-index: 2;
}

.search-content > div {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
}


.skill {
  position: relative;
  padding: 8px 24px;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.field-error {
  color: red;
  font-size: 12px;
}
