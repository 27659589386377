.container {
  width: 23%;
  font-size: 1em;
  display: flex;
  padding: 12px 0;
  font-family: "Helvetica";
  font-weight: 500;
  line-height: 1.2em;
  color: var(--primary-font-color);
}
.container:hover {
  cursor: pointer;
  transform: scale(1.05);
  border: 1px solid var(--primary-blue);
  box-shadow: 0px 0px;
}

.img-container {
  width: 100%;
  height: 10vh;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.img-container > img {
  max-height: 100%;
  max-width: 100%;
}
