.custom-checkbox{
    background-color: white;
    height: 20px;
    min-height: 20px;
    width: 20px;
    min-width: 20px;
    border: 2px var(--secondary-light-gray) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -o-transition: .5s all ease;
    color: white;
    cursor: pointer;
}
.custom-checkbox> *{
    opacity: 0;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -o-transition: .5s all ease;
}
.custom-checkbox~label{
    cursor: pointer;
}
input[type='checkbox']:checked~.custom-checkbox {
    background-color: var(--primary-blue);
    border: none;
}
input[type='checkbox']:checked~.custom-checkbox > * {
    opacity: 1;
}