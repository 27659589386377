.container {
  display: flex;
  flex-direction: column;
}

.container ::-webkit-scrollbar {
  width: 6px; /* Largeur de la scrollbar */
  border-radius: 3px; /* Arrondi des coins de la scrollbar */
}

.container ::-webkit-scrollbar-thumb {
  background-color: var(--primary-light-gray); /* Couleur du "pouce" de la scrollbar */
  border-radius: 3px; /* Arrondi des coins du "pouce" */
}

.default-input-block {
  flex: 1 0 0;
}

.default-label-switch {
  font-weight: 400;
  color:var(--primary-light-gray);
}

.default-label-switch:hover {
  cursor: pointer;
  color: var(--secondary-light-gray);
}

button.toggle {
  padding-bottom: 0.25rem;
  align-items: center;
  background: none;
  border: none;
}

button.toggle:hover{
  color: var(--primary-blue);
}


.rotate-icon {
    transform: rotate(0deg); /* Position initiale à 0° */
    transition: transform 0.3s; /* Transition de 0.3 seconde */
}

.rotate-icon:hover {
    animation: rotate-animation 0.4s linear; /* Animation continue au survol */
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg); /* Rotation initiale à 0° */
    }
    100% {
        transform: rotate(360deg); /* Rotation à -20° à la fin */
    }
}