.info-card {
    height: 50px;
    width: 200px;
    display: flex;
    flex-direction: wrap;
    align-items: center;
    justify-content: start;
    gap: 12px;
}

.info-card .label {
    font-weight: 500;
    font-size: large;
}

.info-card .icon {
    background-color: var(--primary-blue);
    height: 50px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}