.container {
  display: flex;
  flex-direction: column;
}

.job {
    position: relative;
    padding: 8px 24px;
    padding-right : 60px;
    color: black;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin : 5px;
  }
  .delete-job {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 9px;
    right: 8px;
    cursor: pointer;
  }
  .edit-job {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 11px;
    right: 32px;
    cursor: pointer;
  }

.add-bar{
    position: relative;
    padding: 0px;

}
.search{
  padding: 20px ;
  padding-bottom: 0px ;
}
.add-bar>input{
    background-color: white;
    color : black;
    border-right: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.add-bar button{
    width : 300px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.job-container{
  display : flex;
  flex-wrap: wrap;
}

.error-bar{
  margin-top : -30px !important;
  margin-bottom : 10px !important;
}
.error-bar-popup{
  margin-top :0px !important;
  margin-bottom : 10px !important;
}

.rotate-icon {
  transform: rotate(0deg); /* Position initiale à 0° */
  transition: transform 0.3s; /* Transition de 0.3 seconde */
}

.rotate-icon:hover {
  animation: rotate-animation 0.4s linear; /* Animation continue au survol */
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg); /* Rotation initiale à 0° */
  }
  25% {
    transform: rotate(20deg); /* Rotation à +20° à mi-chemin */
  }
  50% {
    transform: rotate(0deg); /* Rotation à -20° à la fin */
  }
  75% {
    transform: rotate(-20deg); /* Rotation à +20° à mi-chemin */
  }
  100% {
    transform: rotate(0deg); /* Rotation à -20° à la fin */
  }
}