.nav-item {
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}
.nav-item.active {
  border-bottom: 6px solid var(--primary-blue);
}

.ul {
  margin-top: 50px;
  padding: 0;
}

.ul > li {
  padding: 0.2em;
  margin: 0;
  list-style-type: none;
}
