.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* height: 100vh;
    width: 100vw; */
    z-index: var(--popup-z-index);
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 32px;
    font-weight: 600;
    cursor: pointer;
    z-index: 2;
}

.popup-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--popup-background-color);
    z-index: 1;
}

.popup-content {
    width: 95%;
    max-height: 80%;
    max-width: 992px;
    z-index: 2;
    overflow: scroll;
}
.popup-sm .popup-content{
    max-width: 380px;
}
.open {
    animation: popup-animation;
    -webkit-animation: popup-animation;
    animation-duration: .5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes popup-animation {
    from {
        opacity: 0;
        transform: scale(.5);
        -webkit-transform: scale(.5);
        -moz-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}
}