.container {
  margin: 2em 2em;
}

.section-header {
  width: 100%;
  padding: 12px;
  background-color: var(--grey-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.activité-icon {
  width: 80px;
  height: 80px;
}
.activité-card {
  overflow: hidden;
  padding-bottom: 24px !important;
}
.activité-card > *:nth-child(1) {
  height: 40px;
}
.progress {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1px 2px;
  padding-right: 8px;
}
.progress[status="blue"] {
  background-color: var(--secondary-blue);
}
.progress[status="red"] {
  background-color: var(--red);
}
.progress > * {
  color: white !important;
}
.circular-progressbar-container {
  width: 160px;
  height: 160px;
}