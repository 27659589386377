.drop-down-menu {
    display: flex;
    flex-direction:column;
    gap:0.25rem;
    position: absolute;
    background-color: white;
    padding: 4px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.drop-down-menu button {
    text-align: start;
    width: 100%;
    border-radius: 5px;
    background: none;
    padding: 4px;
    border: 0;
}

.drop-down-menu button:hover {
    background: var(--primary-light-blue);
    padding: 4px;
    border: 0;
}