.container {
  background-color: white;
  color: black;
  border-radius: 1em;
  margin-top: 6px;
  overflow-x: hidden;
  overflow-y: visible;
  max-height: 150px;
  width: 340px;
  position: absolute;
  z-index: 1;
}



.container::-webkit-scrollbar {
  width: 6px; /* Largeur de la scrollbar */
  border-radius: 3px; /* Arrondi des coins de la scrollbar */
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--blue-ny); /* Couleur du "pouce" de la scrollbar */
  border-radius: 3px; /* Arrondi des coins du "pouce" */
}


.container ul {
  margin: 0;
  padding: 0;
  border-radius: 1em;
}

.container li {
  list-style-type: none;
  padding: 0.5em 1em;
}

.container li:hover {
  background-color: var(--blue-ny);
  color: black;
  cursor: pointer;
  overflow: hidden;
}